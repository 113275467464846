import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
// @mui
import {
  Card,
  Stack,
  Button,
  Checkbox,
  Container,
  Typography,
  Box,
  Paper,
  TextField,
  Grid,
  FormControlLabel,
} from "@mui/material";
// components
import { useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { Carousel } from "react-responsive-carousel";
import { SideBySideMagnifier } from "react-image-magnifiers";
import ReactViewer from 'react-viewer';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Icon } from "@iconify/react";
import Scrollbar from "../components/scrollbar";
import useSurvey from "../hooks/Services/useSurvey";
import { fCurrency } from "../utils/formatNumber";
import TimeLine from "../components/timeline";
import RenderPdfs from "../components/renderPdfs";
import CarCroqui from "../components/carCroqui";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// ----------------------------------------------------------------------

export default function ViewVistoria() {
  const location = useLocation();
  const props = location.state;
  const [numPages, setNumPages] = useState(null);
  const { result, viewSurvey } = useSurvey();
  const [scale, setScale] = useState(1.65);
  const [dataSurvey, setDataSurvey] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const vehiclePhotosRefArray = [
    "vehiclePics",
    "dealerVehiclePics",
    "btckDealerVehiclePics",
  ];

  const vehicleDocsPhotosRefArray = [
    "docVehicle",
    "dealerDocVehicle",
    "btckDealerDocVehicle",
  ];

  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openViewer = (src) => {
    setCurrentImage(src);
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  const photosToShow = dataSurvey && dataSurvey.survey.photos ? 
  dataSurvey.survey.photos.filter((photo) => {
    if (dataSurvey.survey?.status <= 4) {
      return ["vehiclePics"].includes(photo.ref);
    }

    if (dataSurvey.survey?.status === 5) {
      return [
        "dealerVehiclePics",
        "btckDealerVehiclePics",
      ].includes(photo.ref);
    }

    return vehiclePhotosRefArray.includes(photo.ref);
  }) : [];

  const customPageStyles = {
    textLayer: {
      display: "none", // Oculta a camada de texto para evitar a sobreposição
    },
  };

  useEffect(() => {
    viewSurvey(props.id);
    function handleResize() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Lógica para calcular a escala apropriada com base no tamanho da janela
      const newScale = Math.min(windowWidth / 800, windowHeight / 600);
      setScale(newScale);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (Object.keys(result.data).length !== 0) {
      setDataSurvey(result.data);
    }
  }, [result]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Helmet>
        <title>Detalhes</title>
      </Helmet>

      <Container>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <TimeLine status={result.data?.survey?.status} />
          <Typography variant="h4" gutterBottom className="w-100 mt-5">
            Detalhes
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Marca"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.brand || ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Modelo"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.model || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Ano"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.year || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cor"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.color || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Placa"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.plate || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Chassi"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.chassis || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Dados Adicionais"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.additional || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Identificação"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.cod || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className="text-center">
                    <FormControlLabel
                      disabled
                      control={<Checkbox />}
                      label="Veículo Blindado"
                    />
                  </Grid>

                  <Grid item md={3} xs={5}>
                    <TextField
                      name="deliveryCep"
                      value={dataSurvey?.survey?.deliveryCep || ""}
                      fullWidth
                      label="CEP"
                      disabled
                    />
                  </Grid>

                  <Grid item lg={7} md={12} xs={7}>
                    <TextField
                      fullWidth
                      label="Rua"
                      name="deliveryStreet"
                      value={dataSurvey?.survey?.deliveryStreet || ""}
                      disabled
                    />
                  </Grid>

                  <Grid item lg={2} md={12} xs={5}>
                    <TextField
                      fullWidth
                      label="Número"
                      name="deliveryNumber"
                      value={dataSurvey?.survey?.deliveryNumber || ""}
                      disabled
                    />
                  </Grid>

                  <Grid item lg={5} md={12} xs={7}>
                    <TextField
                      fullWidth
                      label="Bairro"
                      name="deliveryDistrict"
                      value={dataSurvey?.survey?.deliveryDistrict || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={5} md={12} xs={9}>
                    <TextField
                      fullWidth
                      label="Cidade"
                      name="deliveryCity"
                      value={dataSurvey?.survey?.deliveryCity || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={2} md={12} xs={3}>
                    <TextField
                      fullWidth
                      label="Estado"
                      name="deliveryState"
                      value={dataSurvey?.survey?.deliveryState || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Complemento"
                      name="deliveryComplement"
                      value={dataSurvey?.survey?.deliveryComplement || ""}
                      disabled
                    />
                  </Grid>
                  {dataSurvey?.survey?.notes?.customerNotes && (
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        value={dataSurvey?.survey?.notes?.customerNotes || ""}
                        disabled
                      />
                    </Grid>
                  )}
                  {dataSurvey?.survey?.notes?.bcNotesToCustomer && (
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações da Impar"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        value={
                          dataSurvey?.survey?.notes?.bcNotesToCustomer || ""
                        }
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>

            <CarCroqui positionsChecked={props?.positionsChecked} updateAim />

            <Card sx={{ boxShadow: 3 }} className="mb-4">
          <Typography variant="h6" gutterBottom>
            Foto do veículo (danos)
          </Typography>
          <Carousel>
            {photosToShow.length > 0 ? (
              photosToShow.map((photo, index) => (
                <div key={index}>
                  <button 
                    style={{ border: 'none', background: 'none', padding: 0 }} 
                    onClick={() => openViewer(index)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        openViewer(index);
                      }
                    }}
                    aria-label={`Ver imagem ${index + 1}`}
                  >
                    <SideBySideMagnifier
                      imageSrc={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                      imageAlt={`Foto do veículo ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </button>
                </div>
              ))
                ) : (
                  <Typography variant="body2">
                    Nenhuma foto anexada
                  </Typography>
                )}
              </Carousel>

              <ReactViewer
                visible={visible}
                onClose={closeViewer}
                images={photosToShow.map(photo => ({ src: `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`, alt: `Foto do veículo` }))}
                index={currentImageIndex}
                zIndex={1000}
              />
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Foto da documentação
              </Typography>
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {dataSurvey && dataSurvey.survey.photos ? (
                    dataSurvey.survey.photos
                      .filter((photo) => {
                        if (dataSurvey.survey?.status <= 4) {
                          return ["docVehicle"].includes(photo.ref);
                        }

                        if (dataSurvey.survey?.status === 5) {
                          return [
                            "dealerDocVehicle",
                            "btckDealerDocVehicle",
                          ].includes(photo.ref);
                        }

                        return vehicleDocsPhotosRefArray.includes(photo.ref);
                      })
                      .map((photo, index) => {
                        const pdf = photo.foto.endsWith(".pdf");

                        return pdf ? (
                          <Grid item xs={4} md={4} key={index}>
                            <Icon
                              style={{ cursor: "pointer" }}
                              width={50}
                              icon="vscode-icons:file-type-pdf2"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                                  "_blank"
                                )
                              }
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={4} md={4} key={index}>
                            <img
                              width={200}
                              src={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                              alt={photo.ref}
                              title={photo.ref}
                            />
                          </Grid>
                        );
                      })
                  ) : (
                    <Grid item xs={12}>
                      <Paper sx={{ textAlign: "center" }}>
                        <Typography variant="h6" paragraph>
                          Sem fotos anexadas
                        </Typography>
                        <Typography variant="body2">
                          Nenhuma foto dos documentos anexada
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Orçamento
              </Typography>
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Mão de obra"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={
                        dataSurvey && dataSurvey?.replies?.[0]
                          ? fCurrency(
                              parseFloat(dataSurvey?.replies?.[0]?.labor, 10)
                            )
                          : "Aguardando"
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Peça"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={
                        dataSurvey && dataSurvey?.replies?.[0]
                          ? fCurrency(
                              parseFloat(dataSurvey?.replies?.[0]?.pieces, 10)
                            )
                          : "Aguardando"
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Total"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={
                        dataSurvey && dataSurvey?.replies?.[0]
                          ? fCurrency(
                              parseFloat(dataSurvey?.replies?.[0]?.labor, 10) +
                                parseFloat(dataSurvey?.replies?.[0]?.pieces, 10)
                            )
                          : "Aguardando"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Descrição"
                      disabled
                      multiline
                      rows={4}
                      variant="outlined"
                      className="w-100"
                      value={
                        dataSurvey?.replies?.[0]?.additional || "Aguardando"
                      }
                    />
                  </Grid>

                  {dataSurvey?.survey?.status === 5 && (
                    <Grid item xs={12} md={12}>
                      <RenderPdfs pdfArray={dataSurvey?.replies} />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>

            {dataSurvey?.survey?.status === 5 && (
              <Card
                sx={{ boxShadow: 3, width: "100%" }}
                className="mb-4 d-flex justify-content-center"
              >
                <Box
                  className="d-flex mb-1 mt-3"
                  style={{ maxWidth: "100vw", overflowX: "auto" }}
                >
                  <Document
                    file={
                      dataSurvey?.replies?.[0]
                        ? `${process.env.REACT_APP_API_ENDPOINT}/replyes/${dataSurvey.replies[0].pdf}`
                        : null
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                    renderMode="canvas"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderTextLayer={false} // Desativa a renderização da camada de texto padrão
                        customStyles={customPageStyles} // Aplica estilos personalizados para cada página
                        renderAnnotationLayer={false}
                        scale={scale}
                      />
                    ))}
                  </Document>
                </Box>
              </Card>
            )}
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
