import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import croqui from "../../img/croqui.png";
import pointGreen from "../../img/svg/pointGreen.svg";
import pointRed from "../../img/svg/pointRed.svg";

const SIZE = "50px";

const POINT_WIDTH = SIZE;
const POINT_HEIGHT = SIZE;
const HALF_WIDTH = `${parseInt(POINT_WIDTH, 10) / 2}px`;

const CarCroqui = ({
  setGetPositionsChecked,
  useFunction = false,
  positionsChecked,
  updateAim = false,
}) => {
  const [positions, setPositions] = useState([
    {
      uuid: uuidv4(),
      namePosition: "Superior Esquerda",
      checked: false,
      top: `calc(40px)`,
      left: `calc(40px)`,
    },
    {
      uuid: uuidv4(),
      namePosition: "Superior Central",
      checked: false,
      top: `calc(15px)`,
      left: `calc(50% - ${HALF_WIDTH})`,
    },
    {
      uuid: uuidv4(),
      namePosition: "Superior Direita",
      checked: false,
      top: `calc(40px)`,
      left: `calc(100% - ${POINT_WIDTH} - 40px)`,
    },
    {
      uuid: uuidv4(),
      namePosition: "Lateral Esquerda",
      checked: false,
      top: "50%",
      left: "40px",
    },
    {
      uuid: uuidv4(),
      namePosition: "Teto",
      checked: false,
      top: "50%",
      left: `calc(50% - ${HALF_WIDTH})`,
    },
    {
      uuid: uuidv4(),
      namePosition: "Lateral Direita",
      checked: false,
      top: "50%",
      left: `calc(100% - ${POINT_WIDTH} - 40px)`,
    },
    {
      uuid: uuidv4(),
      namePosition: "Inferior Esquerda",
      checked: false,
      top: `calc(100% - ${POINT_HEIGHT} - 45px)`,
      left: "50px",
    },
    {
      uuid: uuidv4(),
      namePosition: "Inferior Central",
      checked: false,
      top: `calc(100% - ${POINT_HEIGHT} - 15px)`,
      left: `calc(50% - ${HALF_WIDTH})`,
    },
    {
      uuid: uuidv4(),
      namePosition: "Inferior Direita",
      checked: false,
      top: `calc(100% - ${POINT_HEIGHT} - 40px)`,
      left: `calc(100% - ${POINT_WIDTH} - 50px)`,
    },
  ]);

  useEffect(() => {
    if (useFunction) {
      setGetPositionsChecked(() => {
        const newArray = positions
          ?.filter((item) => item?.checked === true)
          ?.map((item) => item?.namePosition);

        return newArray;
      });
    }
  }, [positions]);

  useEffect(() => {
    if (updateAim) {
      setPositions((prevState) => {
        const newArray = prevState?.map((item) => {
          if (positionsChecked?.includes(item?.namePosition)) {
            return {
              ...item,
              checked: true,
            };
          }

          return item;
        });

        return newArray;
      });
    }
  }, [positionsChecked]);

  const changeCkecked = (uuid) => {
    setPositions((prevState) => {
      const newArray = prevState?.map((item) => {
        if (item?.uuid === uuid) {
          return {
            ...item,
            checked: !item?.checked,
          };
        }

        return item;
      });

      return newArray;
    });
  };

  return (
    <Card sx={{ boxShadow: 3 }} className="mb-4">
      <Typography variant="h6">Área danificada</Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "fit-content",
            position: "relative",
          }}
        >
          <img width={"300px"} src={croqui} alt="Croqui do Carro" />
          {positions
            ?.filter((item) => useFunction || item?.checked)
            ?.map((pos, index) => {
              return (
                <Box
                  onClick={() => changeCkecked(pos?.uuid)}
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    top: pos?.top,
                    left: pos?.left,
                    transition: "0.2s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    "&:active": {
                      transform: "scale(0.9)",
                    },
                  }}
                >
                  <img
                    key={index}
                    src={pos?.checked ? pointRed : pointGreen}
                    alt={`Indicador ${index}`}
                    style={{
                      width: POINT_WIDTH,
                      height: POINT_HEIGHT,
                    }}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    </Card>
  );
};

export default CarCroqui;
